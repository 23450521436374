<template>
  <v-container>
    <v-row class="mt-2">
      <v-col cols="6">
        <v-card>
          <v-system-bar>Schäftli</v-system-bar>
          <v-card-text>
            <ReportButton
              block
              text
              outlined
              resource="report/lockersmissing"
              :parameters="{}"
              >ausgetretene Schüler:innen mit Schlüssel</ReportButton
            >
          </v-card-text>
          <v-card-text>
            <SchoolClassInput
              label="Schüler:innen pro Klasse"
              hide-details
              outlined
              dense
              v-model="schoolClass"
              clearable
            />
            <ReportButton
              class="mt-2"
              v-if="schoolClass && schoolClass.id"
              block
              text
              outlined
              resource="report/lockers"
              :parameters="{
                type: 'class',
                id: schoolClass.id,
              }"
              >Schüler:innen der Klasse {{ schoolClass.code }}</ReportButton
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card class="mb-4">
          <v-system-bar>Krisen-Kit</v-system-bar>
          <v-card-text>
            <ReportButton
              block
              text
              outlined
              class="mb-2"
              resource="report/schoolclasslist"
              :parameters="{ type: 'active' }"
              >Klassenlisten</ReportButton
            >
            <ReportButton
              block
              text
              outlined
              resource="report/schoolclassoverview"
              :parameters="{}"
              >Klassenübersicht</ReportButton
            >
          </v-card-text>
        </v-card>
        <v-card>
          <v-system-bar>Fachschaften</v-system-bar>
          <v-card-text>
            <ReportButton
              block
              text
              outlined
              resource="report/departmentheads"
              :parameters="{}"
              >Fachvorstände</ReportButton
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportButton from '@/components/ReportButton';
import SchoolClassInput from '@/components/SchoolClassInput';

export default {
  name: 'LockerReports',
  components: {
    ReportButton,
    SchoolClassInput,
  },
  data: () => ({
    loading: false,
    schoolClass: false,
  }),
  methods: {
    showDetails(id) {
      this.$router.push({ name: 'LockerDetails', params: { id: id } });
    },
  },
};
</script>
